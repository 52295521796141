import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { Serializer } from "survey-core";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "survey-core/survey.i18n";
import { ComponentCollection } from "survey-core";
import { surveyLocalization } from "survey-core";

// for adding only one another language else comment it for showing all
surveyLocalization.supportedLocales = ["en","ar"];


import {
	getSurveyJSON,
	saveSurveyJSON,
	getSurveyName,
	saveSurveyName,
	getSurveyAndThemeData,
} from "./WebDataService";
ComponentCollection.Instance.add({
    name: "nps",
    title: "NPS Question",
    questionJSON: {
        type: "rating",
		rateCount: 11,
		rateMin: 0,
		rateMax: 10,
		minRateDescription: "Highly Unlikely",
		maxRateDescription: "Highly Likely",
        placeholder: "Select a country...",
        choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample",
        },
    },
	
});
ComponentCollection.Instance.add({
    name: "csat",
    title: "CSAT Question",
    questionJSON: {
        type: "rating",
    rateType: "smileys", 
		rateCount: 5,
          rateMax: 5,
        choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample",
        },
    },
});

Serializer.findProperty("survey", "title").isRequired = true;

export function SurveyCreatorWidget(props) {
	const creator = new SurveyCreator({
		showLogicTab: true,
		showThemeTab: true,
		showJSONEditorTab: false,
		showTranslationTab: true,
	});
// 	const apiKey = "<your-microsoft-translator-api-key>";
// const resourceRegion = "<your-azure-region>";
// const endpoint = "https://api.cognitive.microsofttranslator.com/";
// creator.onMachineTranslate.add((_, options) => {
//   // Prepare strings for Microsoft Translator as an array of objects: [{ Text: "text to translate" }]
//   const data = [];
//   options.strings.forEach(str => { data.push({ Text: str }); });
//   // Include required locales in the URL
//   const params = "api-version=3.0&from=" + options.fromLocale + "&to=" + options.toLocale;
//   const url = endpoint + "/translate?" + params;
//   fetch(url, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Ocp-Apim-Subscription-Key": apiKey,
//       "Ocp-Apim-Subscription-Region": resourceRegion,
//       "X-ClientTraceId": crypto.randomUUID()
//     },
//     body: JSON.stringify(data)
//   }).then(response => response.json())
//     .then(data => {
//       // Convert data received from Microsoft Translator to a flat array
//       const translatedStrings = [];
//       for (let i = 0; i < data.length; i++) {
//         translatedStrings.push(data[i].translations[0].text);
//       }
//       // Pass translated strings to Survey Creator
//       options.callback(translatedStrings);

//     }).catch(error => {
//       // If translation was unsuccessful:
//       options.callback();
//       alert("Could not translate strings to the " + options.toLocale + " locale");
//     });
// });

	var theme = null;
	creator.saveThemeFunc = (saveNo, callback) => {
		window.localStorage.setItem("survey-theme-json", creator.theme);
		callback(saveNo, true);
		theme = JSON.stringify(creator.theme, null, 2);
	};

	creator.isAutoSave = true;
	var hello = null;
	creator.saveSurveyFunc = (saveNo, callback) => {
		const formattedJSON = JSON.stringify(creator.JSON, null, 2);
		console.log("formattedJSON", formattedJSON);
		hello = formattedJSON;
	};

	const id = useCallback(() => {
		let res = props.id;
		if (!res) return -1;
		return Number(res);
	}, [props.id]);

	// for editing the surveys and rendering their previous data
	useEffect(() => {
		const fetchData = async () => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const domainId = urlParams.get("domain_id");
				const editSurveyId = urlParams.get("editsurvey");

				if (editSurveyId) {
					const requestDatas = {
						editsurvey: editSurveyId,
						domain_id: domainId,
					};

					const response = await fetch(
						`${process.env.REACT_APP_API_URL}.edit_survey`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(requestDatas),
						}
					);

					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					const data = await response.json();
					creator.JSON = JSON.parse(data.message.survey_json);
					creator.theme = JSON.parse(data.message.theme_json);
					console.log("creator.theme", creator.theme);
					console.log("creator.JSON", creator.JSON);
				} else {
					// Load survey definition by ID
					getSurveyJSON(id(), json => {
						const prevTitle = creator.survey.title;
						creator.JSON = json;
						if (!!prevTitle) {
							creator.survey.title = prevTitle;
						}
					});
					getSurveyName(id(), name => {
						creator.survey.title = name;
					});
				}
			} catch (error) {
				console.error("Error fetching survey and theme data:", error);
			}
		};

		fetchData(); // Call the async function to fetch data
	}, [id, creator]);

	const handleAPICall = () => {
		const urlParamss = new URLSearchParams(window.location.search);
		const editsurvey = urlParamss.get("editsurvey");
		const domainIdd = urlParamss.get("domain_id");
		// console.log("editsurvey", editsurvey);
		if (editsurvey) {
			if (hello) {
				const requestDatad = {
					editsurvey: editsurvey,
					domain_id: domainIdd,
					pages: hello,
					theme: theme !== null && theme !== undefined ? theme : null,
				};
				// console.log("when editsurvey is there ", theme);
				console.log("when editsurvey is there ", requestDatad);
				fetch(`${process.env.REACT_APP_API_URL}.edit_storing`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestDatad),
				})
					.then(response => response.json())
					.then(data => {
						console.log(" response from edit api:", data);
						toast.success("Survey saved successfully!");
					})
					.catch(error => {
						console.error("Error making POST request:", error);
						toast.error("Error saving survey!");
					});
			} else {
				console.error("Hello data is not available.");
			}
		}
		const urlParams = new URLSearchParams(window.location.search);
		const surveyId = urlParams.get("survey_id");
		const domainId = urlParams.get("domain_id");
		if (surveyId) {
			if (hello) {
				const requestData = {
					survey_id: surveyId,
					domain_id: domainId,
					pages: hello,
					theme: theme !== null && theme !== undefined ? theme : null,
				};

				console.log(requestData);

				fetch(`${process.env.REACT_APP_API_URL}.create_survey`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestData),
				})
					.then(response => response.json())
					.then(data => {
						console.log("POST request response:", data);
						toast.success("Survey saved successfully!");
					})
					.catch(error => {
						console.error("Error making POST request:", error);
						toast.error("Error saving survey!");
					});
			} else {
				console.error("Hello data is not available.");
			}
		}
	};

	const style = { height: "100vh" };

	return (
		<div style={style}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "12px 19px 0px 20px",
				}}
			>
				<Link to="/">
					<img
						src="https://helloapps3494.b-cdn.net/wp-content/uploads/2022/01/Purple_PNG.png.bv_resized_desktop.png.bv.webp?bv_host=helloapps.io"
						alt="Back To List"
						style={{
							cursor: "pointer",
							borderRadius: "4px",
							padding: "1px 1px",
							maxWidth: "150px",
						}}
					/>
				</Link>
				<button
					onClick={handleAPICall}
					type="button"
					className="btn btn--primary back-btn"
				>
					Save Form
				</button>
			</div>
			<SurveyCreatorComponent creator={creator}></SurveyCreatorComponent>
		</div>
	);
}
